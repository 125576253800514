import authReducer, { setAccessDenied } from 'features/auth/authSlice';
import {
  Action,
  configureStore,
  createListenerMiddleware,
  isAnyOf,
  isRejected,
  ThunkAction
  } from '@reduxjs/toolkit';
import { hereMapsApi } from './services/hereMaps';
import { logError } from 'common/utils/helper';
import { solutionApi } from './services/solution';
import type { MiddlewareAPI, Middleware } from '@reduxjs/toolkit';

const getRejectedMatchers = (api: any): any[] =>
  Object.keys(api.endpoints).map((key) => api.endpoints[key].matchRejected);

const listenerMiddleware = createListenerMiddleware();

listenerMiddleware.startListening({
  // @ts-expect-error
  matcher: isAnyOf(...getRejectedMatchers(solutionApi)),
  effect: async (action, listenerApi) => {
    // @ts-expect-error
    const status = action?.meta?.baseQueryMeta?.response?.status;

    if (status === 403) {
      listenerApi.dispatch(setAccessDenied({ accessDenied: true }));
    }
  },
});

const rtkQueryErrorLogger: Middleware = (_: MiddlewareAPI) => (next) => (action) => {
  if (isRejected(action)) {
    // @ts-expect-error
    const error = action?.payload?.error;
    // @ts-expect-error
    const method = action?.meta?.baseQueryMeta?.request?.method;
    // @ts-expect-error
    const url = action?.meta?.baseQueryMeta?.request?.url;

    if (error && method && url) {
      logError(`${error}: ${method} ${url}`);
    }
  }

  return next(action);
};

export const store = configureStore({
  reducer: {
    auth: authReducer,
    [solutionApi.reducerPath]: solutionApi.reducer,
    [hereMapsApi.reducerPath]: hereMapsApi.reducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware()
      .concat(solutionApi.middleware, hereMapsApi.middleware, rtkQueryErrorLogger)
      .prepend(listenerMiddleware.middleware),
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<ReturnType, RootState, unknown, Action<string>>;
